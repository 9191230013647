import React from "react"
import { graphql } from "gatsby"
import Image from "./image"

import SEO from "../components/seo"
import Layout from "../components/layout"
import style from "./project-video.module.scss"

export const query = graphql`
  query($name: String!) {
    file(relativeDirectory: { eq: $name }, name: { eq: "info" }) {
      childYaml {
        title
        credits {
          what
          who
        }
        transparentPng
      }
    }
    thumbnail: file(
      childImageMetadata: { internal: { type: { eq: "ImageMetadata" } } }
      relativeDirectory: { eq: $name }
    ) {
      ...Image
    }
    videoFiles: allFile(
      filter: {
        internal: { mediaType: { regex: "/video/" } }
        relativeDirectory: { eq: $name }
        name: { eq: "output" }
      }
    ) {
      edges {
        node {
          publicURL
        }
      }
    }
  }
`

class ProjectsPage extends React.Component {
  constructor(props) {
    super(props)
    const {
      childYaml: { title, credits, transparentPng },
    } = props.data.file || {
      childYaml: { title: props.pageContext.name, credits: [] },
    }
    this.title = title.replace(/\t/g, "")
    this.credits = credits || []
    this.transparentPng = transparentPng
  }

  componentDidMount() {
    document.documentElement.classList.add("inverse")
  }

  componentWillUnmount() {
    document.documentElement.classList.remove("inverse")
  }

  render() {
    return (
      <Layout fixedHeight>
        <div className={style.wrapper}>
          <SEO title={this.title} />
          <h1>{this.title}</h1>
          <dl className={style.credits}>
            {this.credits.map(({ what, who }, i) => {
              return (
                <div key={i}>
                  <dt>{what}</dt>
                  <dl>{who}</dl>
                </div>
              )
            })}
          </dl>
          <div className={style.videoWrapper}>
            <Image
              metadata={this.props.data.thumbnail.childImageMetadata}
              className={style.thumbnail}
            />
            <video controls poster={this.transparentPng}>
              {this.props.data.videoFiles.edges.map(({ node: source }) => (
                <source src={source.publicURL} />
              ))}
            </video>
          </div>
        </div>
      </Layout>
    )
  }
}

export default ProjectsPage
